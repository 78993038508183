.dragging {
    opacity: .2;
}

.drop-area-active {
    filter: blur(1.5px);
}

.drop-area-active * {
    pointer-events: none;
}